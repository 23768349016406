<template>
    <div class="page-engage">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container>

          <div>
              <h1>
                  <P style="text-align:center;margin-bottom: 20px;">
                      ENGAGEMENT AGREEMENT                    
                  </P>
              </h1>
          </div>
          <div id="Form Body" style="border-style: solid; border-color:grey;padding: 20px;">
              <v-row id="Company">
                  <v-col cols="12">
                      <b-input-group>
                      <template #prepend>
                          <b-input-group-text>Company Name:</b-input-group-text>
                      </template>
                      <b-form-input
                          v-model="engagementItem.company_name"
                          class="form-input"
                          style="text-align: center; font-weight: 700;"
                          ref="companyName"
                          required
                      >
                      </b-form-input>
                      </b-input-group>
                  
                  </v-col>
              </v-row>
              <v-row id="DBA">
                  <v-col cols="5">
                      <b-input-group>
                      <template #prepend>
                          <b-input-group-text>•	Company DBA Name:</b-input-group-text>
                      </template>
                      <b-form-input
                          v-model="engagementItem.company_dba_name"
                          class="form-input"
                      >
                      </b-form-input>
                      </b-input-group>
                  </v-col>
                  <v-col cols="7">
                      <b-input-group>
                      <template #prepend>
                          <b-input-group-text>Primary Seller:</b-input-group-text>
                      </template>
                      <b-form-input
                          v-model="engagementItem.primary_seller_first_name"
                          :rules="[(v) => !!v || 'This field is required']"
                          class="form-input"
                      >
                      </b-form-input>
                      <b-form-input
                          v-model="engagementItem.primary_seller_last_name"
                          :rules="[(v) => !!v || 'This field is required']"
                          class="form-input"
                      >
                      </b-form-input>                
                      </b-input-group>
                  </v-col>            
              </v-row>  
              <v-row id="Other Names">
                  <v-col cols="12">
                      <b-input-group>
                      <template #prepend>
                          <b-input-group-text>•	Other Seller’s Names:</b-input-group-text>
                      </template>
                      <b-form-input
                          v-model="engagementItem.other_sellers_names"
                          class="form-input"
                      >
                      </b-form-input>
                      </b-input-group>
                  </v-col>
              </v-row>
              <v-row id="Start Date">
                  <v-col cols="6" >
                      <b-input-group>
                      <template #prepend>
                          <b-input-group-text  
                          >Engagement Start Date:  :</b-input-group-text
                          >
                      </template>
                      <b-form-datepicker
                          class="form-input"
                          v-model="engagementItem.engagement_start_date"
                      ></b-form-datepicker>
                      </b-input-group>
                  </v-col>
                  <v-col cols="6">
                      <b-input-group>
                      <template #prepend>
                          <b-input-group-text>Website:</b-input-group-text>
                      </template>
                      <b-form-input 
                          v-model="engagementItem.website" 
                          class="form-input"
                          ></b-form-input>
                      </b-input-group>
                      
                  </v-col>

              </v-row>
              <v-row id="Commission Rate">
                  <v-col cols="4">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text>Commission Rate:</b-input-group-text>
                      </template>
                      <b-form-input v-model.number="engagementItem.commission_rate"
                        class="form-input-percent"
                        type="number"
                        :min="0"
                        :max="100"
                        :step="1"
                        v-mask="mask.rate"
                    >
                      </b-form-input>
                    </b-input-group>
                  </v-col>
                  <v-col cols="4">
                    <b-input-group class="align-items-center">
                      <template #prepend>
                        <b-input-group-text style="width: 155px">Annual Receipts:</b-input-group-text>
                      </template>
                      <money
                        v-model="engagementItem.annual_receipts" 
                        class="form-input-money"
                        style="height: 38px; text-align: center;"
                      ></money>
                    </b-input-group>
                  </v-col>
         
                  <v-col cols="4">
                    <b-input-group  class="align-items-center">
                      <template #prepend>
                        <b-input-group-text style="width: 165px">Annual Cashflow:</b-input-group-text>
                      </template>
                      <money
                        v-model="engagementItem.annual_cashflow" 
                        class="form-input-money"
                        style="height: 38px; text-align: center;"
                      ></money>
                    </b-input-group>
                  </v-col>  
              </v-row>
              <v-row id="List Price">
                  <v-col cols="5">
                      <b-input-group>
                      <template #prepend>
                          <b-input-group-text>List Price:</b-input-group-text>
                      </template>
                      <money
                        v-model="engagementItem.list_price" 
                        class="form-input-money"
                        style="height: 38px; text-align: center; "
                      ></money>                      
                      </b-input-group>
                  </v-col>
                  <v-col cols="7">
                      <b-input-group>
                      <template #prepend>
                          <b-input-group-text>Minimum Acceptable Sales Price:</b-input-group-text>
                      </template>
                      <money
                        v-model="engagementItem.minimum_acceptable_sales_price" 
                        class="form-input-money"
                        style="height: 38px; text-align: center; "
                      ></money>                      
                      </b-input-group>
                  </v-col>
              </v-row>

              <p style="text-align: right; margin-top:20px;">
                  <button class="lite-blue-button" @click="saveFormData">
                      SAVE
                  </button>  
              </p>

            
          </div>

        

        </v-container>
      </v-form>

    </div>
  </template>
  
  <script>
  import CommonServices from "../service/CommonServices";
  import XLSX from "xlsx";
  import { mask } from "vue-the-mask";
  import '../filters/filters.js'

  
  export default {
    components: {},
    directives: {
      mask,
    },



    async created() {
      this.checkPermissions();
      this.logActivity("Opened Engagement Agreement");
  
      this.getDataList();
  
      this.usCurrency = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
   
      this.percentage = new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });     
    },
  
    data() {
      return {
        componentURL: `EngagementAgreement/?deal_id=${this.$route.params.id}`,
        componentURL_base: "EngagementAgreement",
        canpost: localStorage.getItem("canpost"),
        candelete: localStorage.getItem("candelete"),
        canput: localStorage.getItem("canput"),
        
        isAdmin: localStorage.getItem("Admin"),
        isBroker: localStorage.getItem('role')==2,
        isSeller: localStorage.getItem('role')==3,  
        isBuyer: localStorage.getItem('role')==4,
        isDIY: localStorage.getItem('role')==7,
        role: localStorage.getItem('role'),
    
        valid: false,
        dialog: false,
        dialogDelete: false,
        dialogEdit: false,
        deletedId: null,
        snackbar: false,
        snackColor: "",
        snackText: "",
        error: false,
        loading: false,
        editedIndex: -1,
        searchText: "",
        currentDeal: localStorage.getItem("Deal"),
        currentUser:
          localStorage.getItem("first_name") +
          " " +
          localStorage.getItem("last_name"),
  
        currency: "$",
  
        usCurrency: null,
        percentage: null,
  
        engagementItem: {
          id: '',
            deal_id: '',
            company_name: '',
            company_dba_name: '',
            primary_seller_first_name: '',
            primary_seller_last_name: '',
            other_sellers_names: '',
            engagement_start_date: '',
            website: '',
            commission_rate: null,
            annual_receipts: null,
            annual_cashflow: null,
            list_price: null,
            minimum_acceptable_sales_price: null   
        },



        money: {
          decimal: ".",
          thousands: ",",
          prefix: "$ ",
          suffix: " ",
          precision: 0,
          masked: false,
        },
  
        items: [],
  
        userActivityItem: {
          user_id: localStorage.getItem("userid"),
          logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
          page: "EngagementAgreement",
          action: "",
          deal_id: "",
          json: "",
        },
  
        mask: {
          maxDollar: "############",
          currency: "$###,###,###",         
          zipCode: "#####",
          ssn: "###-##-####",
          value: "###,###,###",
          phone: "(###) ###-####",
          rate: "##"
        },
      };
    },


    computed: {
      formattedValue: {
        get() {
          return this.engagementItem.annual_receipts ? this.engagementItem.annual_receipts.toString().replace(",", "") : null;
        },
        set(value) {
          this.engagementItem.annual_receipts = value ? parseInt(value) : null;
        },
      },
    },

  watch: {

  },

    methods: {
      checkPermissions() {
        if (this.isAdmin == "false" && this.isBroker=="false") this.$router.push("/");
      },

      formatPercentage(value) {
        console.log('Formatted Percentage:',this.percentage.format(value))
        return this.percentage.format(value);
      },
      

      async logActivity(activity, dealid, json) {
        this.userActivityItem.action = activity;
        this.userActivityItem.logdate = this.$moment(String(Date())).format(
          "YYYY-MM-DDThh:mm"
        );
        this.userActivityItem.deal_id = dealid;
        this.userActivityItem.json = json;
        await CommonServices.postData("UserActivityLog", this.userActivityItem);
      },
  
      openTasks() {
        this.$router.push(`/Tasks/${this.$route.params.id}`);
      },

      saveFormData() {
      if(!this.engagementItem.primary_seller_first_name || !this.engagementItem.primary_seller_last_name){
        window.alert('You must enter a First and Last name for the primary seller.')
        return
      }  
      if (this.$refs.form.validate()) {  
        console.log('Engagement Data',this.engagementItem)
        if (this.engagementItem.engagement_start_date) {
          this.engagementItem.engagement_start_date = this.$moment(
            String(this.engagementItem.engagement_start_date)
          ).format("MM/DD/YYYY");
        } else {
          this.engagementItem.engagement_start_date = this.$moment(String(Date())).format(
            "MM/DD/YYYY"
          );
        }
      //   if(!this.engagementItem.annual_receipts){
      //     this.engagementItem.annual_receipts= parseFloat(this.engagementItem.annual_receipts.toFixed(2))
      //     this.engagementItem.annual_receipts=parseFloat(this.engagementItem.annual_receipts.replace(/[^\d.-]/g, ""));
      //   return
      //  }       
        if (this.engagementItem.id) {
          console.log("Saving form..id:", this.engagementItem.id);        
          CommonServices.updateData(this.componentURL_base, this.engagementItem)
            .then((response) => {
              const index = this.items.findIndex(
                (item) => item.id === response.id
              );
              this.$set(this.items, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
              // this.logActivity(
              //   "Saved Form Data",
              //   this.engagementItem.deal_id,
              //   JSON.stringify(this.engagementItem)
              // );
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        } else {
          this.engagementItem.deal_id = this.$route.params.id;
          CommonServices.addRecord(this.componentURL_base, this.engagementItem)
            .then((response) => {
              this.items.push(response);
              console.log("Response ID:", response.id);
              this.engagementItem.id = response.id;
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        }
      }
    },  
  
      openOffers() {
        this.$router.push(`/DealOffers/${this.$route.params.id}`);
      },
  
      openUploads() {
        //First write any missing Deal Folders to the upload table
        CommonServices.putData("Functions", { id: 3 })
          .then(() => {
            setTimeout(() => {
              this.$router.push(`/Uploads/${this.$route.params.id}`);
            }, 500);
          })
          .catch((error) => {
            console.error("Put Function Error: ", error);
          });
      },
  
  
      exportToExcel() {
        // On Click Excel download button
        // export json to Worksheet of Excel
        var reportWS = XLSX.utils.json_to_sheet(this.items);
        var wb = XLSX.utils.book_new(); // make Workbook of Excel
  
        // add Worksheets to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, reportWS, "Engagement Agreement"); // sheetAName is name of Worksheet
  
        // export Excel file
        XLSX.writeFile(wb, "Engagement Agreement.xlsx");
      },

      getDataList() {
      CommonServices.getList(this.componentURL)
        .then((res) => {
          console.log("Res: ", res);
          if (res.count == 0) {
            this.resetItems();
          } else {
            this.getData(res.results[0].id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

      getData(ID) {
      console.log("Getting Engagement Detail for: ", ID);
      CommonServices.Detail(ID, this.componentURL_base)
        .then((res) => {
          console.log("Engagement Detail Response: ", res);
          this.engagementItem = { ...this.engagementItem, ...res };
            if (this.engagementItem.engagement_start_date)
            this.engagementItem.engagement_start_date = this.$moment(
              String(this.engagementItem.engagement_start_date)
            ).format("YYYY-MM-DD");        
        })
        .catch((err) => {
          console.log(err);
        });
    },

      // getData() {
      //   CommonServices.getList(this.componentURL)
      //     .then((response) => {
      //       console.log("engagementItem:", response.results);            
      //       this.engagementItem = response.results;

      //       if (this.engagementItem.engagement_start_date)
      //       this.engagementItem.engagement_start_date = this.$moment(
      //         String(this.engagementItem.engagement_start_date)
      //       ).format("YYYY-MM-DD");
      //       })

      //     .catch((error) => {
      //       this.snackbar = true;
      //       this.snackColor = "error";
      //       this.snackText = "Error pulling data " + this.componentURL;
      //       console.error(error);
      //     });
      // },
  
      goBack() {
        return this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style src="../css/styles.css" scoped>

  input[type="number"]::after {
    content: "%";
  }
</style>
  <style scoped>
    